#booking-form {
    form {
        width: 49em;
        max-width: 100%;
        padding: 0 1.5em 2em;
        margin: 0 auto;
        .car-wrapper {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            li {
                list-style: none;
                width: 100%;
            }

            li:not(:last-child) {margin-bottom: 1em}

            .submit-car-booking {
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                padding: 1em;
                border-radius: var(--border-radius-s);
                background: var(--card-overlay);
                box-shadow: var(--card-shadow);
                overflow: hidden;
                min-width: 100%;
            }
        }

    }
}
