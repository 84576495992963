@import '~styles/_mixins.scss';

.form-label {
    @include label-m();
    display: block;
    position: relative;
    margin-bottom: 1rem;
    text-align: left;
}

.form-field {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    border: 1px solid var(--dark-text);
    margin-top: .5em;
    border-radius: var(--border-radius-s);
    background: var(--white);

    &.invalid {border-color: var(--error)}
    &.with-side-content input {padding-right: 2.5em}

    textarea {
        resize: vertical;
        position: relative;
        z-index: 1;
        flex: 0 0 100%;
        width: 100%;
        border: none;
    }

    > input {
        flex: 0 0 100%;
        width: 100%;
        border: none;

        + * {
            flex: 0 0 auto;
            padding-right: .5em;
            transform: translateX(-100%);
        }

        + svg {
            box-sizing: content-box;
            pointer-events: none;
        }

        + button {
            align-self: stretch;
            padding-left: .5em;
        }
    }
}

.form-label.password-field {
    .password-eye-wrap {position: relative}

    .password-eye {
        border-radius: 0;
        color: var(--dark-text);

        span {
            position: absolute;
            top: 80%;
            right: auto;
            bottom: auto;
            left: 15%;
            height: 2px;
            width: 100%;
            background: currentColor;
            transform: rotate(-42deg) scaleX(0);
            transform-origin: bottom left;
            transition: transform .2s;

            &.password-eye-liner {transform: rotate(-42deg) scaleX(1)}
        }

        svg {display: block}
    }
}

.custom-toggle {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 1em;
    text-align: left;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .switch {
        flex: 0 0 2.4375rem;
        width: 2.4375rem;
        height: 1.5rem;
        border-radius: 1em;
        background: var(--main-text);
        transition: background .2s,
                    border .2s;

        &-btn {
            width: calc(1.5rem - 4px);
            height: calc(1.5rem - 4px);
            display: inline-block;
            border-radius: 50%;
            margin: 2px;
            background: var(--main-background);
            vertical-align: top;
            transition: margin .2s,
                        background .2s;
        }
    }

    &.on .switch-btn {margin-left: calc(100% - (1.5rem - 2px))}
}

.select-input {
    @include label-m();

    &.disabled {
        svg {color: var(--grey-dark-25)}
    }

    div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: .5em 0 2em;
    }

    select {
        appearance: none;
        line-height: initial;
    }

    option {
        padding: inherit;
        transition: background .2s;

        &:checked {background: var(--secondary-background)}
    }

    svg {
        width: 40px;
        height: 100%;
        margin-left: -40px;
        padding: .5em;
        pointer-events: none;
        transform: rotate(180deg);
        color: var(--dark-text);
    }
}

.autocomplete-container {
    position: relative;

    &.open {
        .autocomplete-list {display: block}
    }

    .autocomplete-list {
        display: none;
        list-style: none;
        position: absolute;
        top: 100%;
        right: 0;
        bottom: auto;
        left: 0;
        z-index: 2;
        padding: 0;
        margin: -1.3em 0 0 0;
        border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
        background: var(--white);
        box-shadow: var(--box-shadow-s);

        button {
            @include label-s();
            width: 100%;
            padding: .25em .5em;
            border-radius: 0;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: background .2s;

            &:hover,
            &:focus {background: var(--grey-dark-10)}
        }
    }
}
