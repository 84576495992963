// From designs "Text styles" page

@mixin h1() {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.08333;
}
@mixin h2() {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.125;
}
@mixin h3() {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.16667;
}
@mixin h4() {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
}
@mixin h5() {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin body-l() {
    font-size: 1.125rem;
    line-height: 1.25;
    font-weight: normal;
}
@mixin body-m() {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: normal;
}
@mixin body-s() {
    font-size: .875rem;
    line-height: 1.28571;
    font-weight: normal;
}
@mixin body-xs() {
    font-size: .75rem;
    font-weight: normal;
    line-height: 1.33333;
}
@mixin body-xxs() {
    font-size: .625rem;
    font-weight: normal;
    line-height: 1.6;
}
@mixin label-l() {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.28571;
}
@mixin label-m() {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.28571;
}
@mixin label-s() {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin label-xs() {
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.8;
}
@mixin button-text() {
    color: var(--dark-text);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
}
@mixin big-button() {
    @include label-m();
    display: flex;
    align-items: center;
    text-align: center;
    word-break: break-word;
    border-radius: var(--border-radius-m);
    background: var(--secondary-background);
    color: var(--dark-text);
    width: 35rem;
    max-width: calc(100% - 2rem);
    margin: 0 auto 2em;
    padding: 1.25em 1em;

    > svg {
        flex: 0 0 auto;
        margin-right: 1em;
    }

    > span {margin: auto}
}
@mixin button-primary() {
    @include button-text();
    display: block;
    background: var(--secondary-background);
    text-align: center;
    user-select: none;
    padding: calc(.625em - 1px) 1em;
    min-width: max-content;
    width: 25rem;
    max-width: calc(100% - 4rem);
}
@mixin button-secondary() {
    @include button-text();
    color: var(--main-text);
    display: block;
    background: transparent;
    text-align: center;
    user-select: none;
    padding: calc(.625em - 1px) 1em;
    min-width: max-content;
    width: 25rem;
    max-width: calc(100% - 4rem);
    border: 1px solid var(--brand-grass);
}

// Other / Misc.
@mixin text-input-field() {
    @include body-m();
    appearance: none;
    font-family: inherit;
    line-height: initial; // Prevents cutoff text
    color: var(--dark-text);
    border-radius: var(--border-radius-s);
    background: var(--white);
    width: 100%;
    border: 1px solid var(--dark-text);
    padding: calc(.75em - 1px) 1em;
    transition: border .2s,
                box-shadow .2s,
                background .2s,
                color .2s;

    &:focus {
        box-shadow: var(--box-shadow-primary);
        outline: none;
    }

    &:disabled {
        background: var(--grey-dark-40);

        @at-root :root.light #{&} {background: var(--grey-dark-25)}
    }
    &::placeholder {color: var(--grey-dark-40)}
}
@mixin visually-hidden() {
    position: fixed;
    left: -10000px;
    margin-right: 0;
    transform: none;
    z-index: -1;
    opacity: 0;
}
@mixin tag() {
    @include label-m();
    display: inline-block;
    background: var(--brand-grass);
    border-radius: .75rem;
    padding: .2em 1em;
}
@mixin tooltip-top-hover() {
    bottom: 105%;
    opacity: 1;
    color: var(--dark-text-color);
}
@mixin add-button() {
    display: block;
    background: var(--brand-grass);
    border-radius: 50%;
    height: 5.5rem;
    width: 5.5rem;
}
