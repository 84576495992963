@import '~styles/_mixins.scss';

#fuel-form {
    &.has-image {
        .camera-view {display: none}
    }

    form {
        width: 49em;
        max-width: 100%;
        padding: 0 1.5em 2em;
        margin: 0 auto;
    }

    .fuel-image-button-wrap {margin-bottom: 1rem}

    .fuel-image-changer {
        width: 100%;
        margin-bottom: .5rem;
        transition: border .25s;
        .form-field {
            @include button-text();
            min-height: 3rem;
            cursor: pointer;
            span {
                transform: none;
                padding-left: 1rem;
                font-weight: normal;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 35vh;
            object-fit: contain;
            transform: none;
        }

        @at-root :root.light #{&} {
            background: var(--main-background);
            border-color: var(--main-text);
        }
    }

    .dropdown {
        width: 100%;
        margin-bottom: 2.5rem;

        &-label {
            flex: 0 0 100%;
            margin-bottom: .5rem;
        }

        &-toggle {
            flex: 1 1 auto;
            display: flex;
            padding: calc(.75em - 1px) 0 calc(.75em - 1px) 1em;
        }

        &-handle {
            align-items: stretch;

            svg {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

        .chosen {
            @include body-m();
            margin: 0;
        }
    }
    input[type='file'] {@include visually-hidden()}
    [type='submit'] {margin: 2em auto}
}
