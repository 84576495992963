@import '~styles/_mixins.scss';

.text-and-file-input {
    [type='file'] {@include visually-hidden()}
    [type='number'] {padding-right: 2em !important}

    .file-input-label {
        align-self: stretch;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        cursor: pointer;
        padding-right: .2em;
        color: var(--dark-text);

        &.invalid {color: var(--error)}

        svg {display: block}
    }
}
