@import '~styles/_mixins.scss';

.core-wrapper {
    display: flex;
    flex-flow: row;
    height: 100%;

    .core-content {
        height: 100%;
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
    }
}

.loading-bar {
    position: fixed;
    background-color: var(--secondary-background);
    height: 3px;
    z-index: 3;
    top: 0;
}
