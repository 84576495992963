@import '~styles/_mixins.scss';

#vehicle {
    $header-width: 35em;

    form {padding: 2em 1em 4em}

    [type='text'],
    [type='date'],
    [type='number'] {
        padding: calc(.5em - 2px) .5em;
        border-color: transparent;
    }

    [disabled] {
        opacity: 1;
        background: var(--grey-dark-40);
        color: var(--grey-dark-40);
    }

    .form-label {
        flex: 0 0 calc(50% - .5em);
        width: calc(50% - .5em);
        margin: 0;
        text-overflow: ellipsis;

        &.full-width {
            flex: 0 0 100%;
            width: 100%;
        }

        @media screen and (max-width: 31.25rem) {
            flex: 0 0 100%;
            width: 100%;
        }
    }

    .vehicle {
        &-header {
            width: $header-width;
            max-width: 100%;
            padding: 1em 1em 0;
            margin: 0 auto;

            h1 {
                text-align: center;
                padding: 0 .5rem;
            }

            p {
                padding: 0 .5rem;
                margin: 2em auto 0;
            }
        }

        &-form-actions {
            visibility: hidden;
            position: fixed;
            top: 100%;
            right: 0;
            bottom: auto;
            left: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: 0 1em 1em;
            box-shadow: inset 0 -4em 1em -2em var(--main-background);
            transition: visibility .2s,
                        transform .2s,
                        box-shadow .25s;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2em;
                height: 2em;
                border: 2px solid var(--secondary-background);
                background: var(--white);

                svg {
                    margin: 0 auto;
                    width: 1em;
                    height: 1em;

                    use {fill: var(--dark-text)}
                    // ^ Doesn't update from svg color :thinkxel: Might be chrome bug?
                }
            }

            > * {box-shadow: var(--box-shadow-m)}
        }
    }

    [type='text']:not([disabled]),
    [type='date']:not([disabled]) {
        background: var(--white);
        border-color: var(--dark-text);
        color: var(--dark-text);

        &:focus {border-color: var(--secondary-background)}
    }

    &.touched {
        .vehicle-form-actions {
            visibility: visible;
            transform: translateY(-100%);
        }
    }

    @at-root :root.light & {
        [disabled] {background: var(--grey-dark-10)}

        .vehicle {
            &-fieldset {
                border-color: currentColor;

                button svg:first-child {filter: invert(0)}
            }
        }

        [type='text']:not([disabled]),
        [type='date']:not([disabled]) {background: var(--white)}

    }

    @media screen and (min-width: 74rem) {
        > form {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
        }

        .vehicle {
            &-header {
                float: left;
                position: sticky;
                top: 0;
                width: 40em;
                margin-right: 10vw;

                h1 {
                    text-align: left;
                    margin-top: .5em;
                }

                p {
                    @include h4();
                    font-weight: normal;
                    line-height: 1.4;
                    margin-left: 0;
                }
            }

            &-fieldset {margin: 0 1em 2vw}

            &-form-actions {
                box-sizing: content-box;
                visibility: visible;
                right: auto;
                width: $header-width;
                padding: 0 3em 2em 0;
                box-shadow: inset 0 -5em 1em -2em var(--main-background);

                > * {box-shadow: none}
            }
        }
    }
}
