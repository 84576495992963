@import '~styles/_mixins.scss';

.jslib-camera-view {
    .error {
        @include label-s();
        visibility: hidden;
        opacity: 0;
        position: relative;
        top: -1rem;
        padding-top: 1em;
        white-space: pre-wrap;
        text-align: center;
        color: var(--error);
        transition: visibility .2s,
                    opacity .2s,
                    top .2s;

        &.has-error {
            visibility: visible;
            opacity: 1;
            top: 0;
        }
    }
}