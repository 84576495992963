@import '~styles/_mixins.scss';

#mileage {
    .deleted {display: none !important}

    .mileage-card {
        display: flex;
        flex-flow: row wrap;
        border-radius: var(--border-radius-s);
        text-align: center;
        box-shadow: var(--card-shadow);
        background: var(--card-overlay);
        overflow: hidden;

        @at-root :root.light #{&} {background: var(--grey-dark-3)}
    }
}
