@import '~styles/_mixins.scss';

.vehicle-fieldset {
    background: var(--card-overlay);
    border-radius: var(--border-radius-xxs);
    min-inline-size: 0;
    width: 34em;
    max-width: 100%;
    margin: 0 auto 5vw;
    border: 1px solid transparent;
    transition: border .25s;

    &.open {
        > button svg:last-child {transform: scaleY(-1)}

        .vehicle-fieldset-wrap {
            visibility: visible;
            opacity: 1;
            height: auto;
            padding-top: 1em;
            padding-bottom: 1em;
            border-top: 1px solid var(--grey-dark-10);
        }
    }

    .dropdown {
        width: 100%;
        margin-bottom: 1.3em !important;

        .dropdown-label {
            white-space: normal;
            margin-bottom: .5em;
        }

        .dropdown-toggle {
            overflow: hidden;
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            padding: .3em 0;

            svg {padding-right: .5em}
        }

        .list {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 21vh;
        }

        .chosen {
            overflow: hidden;
            text-overflow: ellipsis;

            + svg {flex: 0 0 auto}
        }
    }


    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: .75em 1em;
        margin: 0;

        svg {
            flex: 0 0 auto;

            &:first-child {
                margin: 0 auto 0 0;
                filter: invert(1);
            }

            &:last-child {
                margin-left: auto;
                transition: transform .2s;
            }
        }
    }

    .vehicle-fieldset-label {
        @include label-s();
        margin: 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .vehicle-fieldset-wrap {
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-end;
        height: 0;
        padding: 0 1em;
        border-top: 0 solid transparent;
        transition: opacity .2s,
                    padding .2s,
                    border .2s;
    }

    .field-meta {@include body-xxs()}
}
