@import '~styles/_mixins.scss';

.dropdown {
    position: relative;
    display: inline-block;

    &.open {
        .backdrop {
            visibility: visible;
            background: var(--grey-dark-25);
        }

        .dropdown-toggle svg {transform: scaleY(-1)}

        & .list {
            visibility: visible;
            opacity: 1;
            margin-top: 1em;

            @at-root .compact#{&} {margin-top: 0}
        }
    }

    &.left {
        .list:not(.no-tail) {
            right: auto;
            left: 0;

            &::before {
                right: auto;
                left: 0;
            }
        }
    }

    &.ticks-left {
        .list {
            li {
                button {
                    justify-content: flex-start;
                    padding: .2em 1em .2em 0;

                    .tick {
                        order: -1;
                        margin: 0 1em 0 .5em;
                    }
                }
            }
        }
    }

    &.above .list {
        top: auto;
        bottom: 100%;
    }

    .backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 101;
        background: transparent;
        cursor: pointer;
        visibility: hidden;
        transition: background .2s,
                    visibility .2s;

        &.no-backdrop {visibility: hidden !important} // stylelint-disable-line declaration-no-important
    }

    &-handle {
        position: relative;
        border: none;
        border-radius: 0 !important;
        background: none !important;
        white-space: nowrap;
        width: 100%;
        text-align: left;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        filter: none !important;

        &[disabled] {
            .dropdown-toggle {
                background: #999999; // Mix of white and rgba(black, .4)

                @at-root :root.light #{&} {background: var(--grey-dark-10)}
            }

            .chosen {color: var(--grey-dark-40)}
        }

        .chosen {
            @include button-text();
            margin-left: .5rem;
            color: var(--dark-text);
        }

        svg {
            box-sizing: content-box;
            transition: transform .2s;
            transform: scaleY(1);
            vertical-align: middle;
            color: var(--dark-text);
            margin-left: auto;
            padding: 0 .7em 0 .3em;
        }
    }

    &-label {
        @include label-m();
        color: var(--main-text);
        transition: color .25s;
    }

    &-toggle {
        border: 1px solid var(--dark-text);
        background: var(--white);
        border-radius: var(--border-radius-xxs);
        transition: border .25s;
    }

    .list {
        position: absolute;
        top: 100%;
        right: 0;
        bottom: auto;
        left: auto;
        z-index: 101;
        list-style: none;
        min-width: 4em;
        padding: .7em .5em;
        margin: 0;
        background: var(--white);
        border-radius: var(--border-radius-s);
        box-shadow: var(--box-shadow-m);
        visibility: hidden;
        opacity: 0;
        transition: visibility .2s,
                    opacity .2s,
                    margin .2s;

        @at-root .compact#{&} {padding: .3em .5em}

        &:not(.no-tail)::before {
            content: '';
            position: absolute;
            top: auto;
            right: 0;
            bottom: 100%;
            left: auto;
            margin: 0 1em -.5em;
            width: 1em;
            height: 1em;
            background: inherit;
            transform: rotate(45deg);
        }

        li {
            button {
                background: none;
                border: 0;
                border-radius: var(--border-radius-xs);
                color: var(--dark-text);
                min-width: max-content;
                width: 100%;
                padding: .2em 1em;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                outline: none;
                white-space: nowrap;
                user-select: none;
                text-align: left;
                transition: background .2s,
                            color .2s,
                            filter .2s;

                &:hover {
                    color: var(--fade-background);

                    @at-root :root.light #{&} {color: var(--grey-dark-40)}
                }

                &:focus {background: var(--grey-dark-10) !important} // stylelint-disable-line declaration-no-important

                @at-root .compact#{&} {padding: .2em 0}

                &.selected {
                    background: var(--secondary-background);
                    color: var(--white);

                    svg {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .tick {
                    visibility: hidden;
                    opacity: 0;
                    flex: 0 0 auto;
                    margin: 0 -.5em 0 2em;
                    width: 1.5em;
                    height: 1.5em;
                    padding: .25em;
                    color: var(--dark-text);
                    transition: visibility .2s,
                                opacity .2s;
                }
            }
        }
    }

    .not-found {margin-left: 1em}

    .type-to-filter {
        @include label-s();
        padding: .5rem 1rem;
    }
}
