@import '~styles/_mixins.scss';

// Needs higher specificity (with :root, in this case) to override base.scss [type='button'] styles
:root .card-with-image {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding: 1em;
    border-radius: var(--border-radius-s);
    background: var(--card-overlay);
    box-shadow: var(--card-shadow);
    overflow: hidden;

    @at-root :root.light #{&} {background: var(--grey-dark-3)}

    .card {
        &-content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding-right: 4rem;
            margin-right: 1em;
            text-align: left;
        }

        &-label {
            @include label-m();
            word-break: break-word;
        }

        &-secondary {
            @include body-xs();
            display: block;
            margin-top: .5em;
        }

        &-image-wrap {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            width: 4rem;
            height: 100%;
        }

        &-image {
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
