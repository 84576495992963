@import '~styles/_mixins.scss';

@keyframes pulse {
    0% { transform: scale(1) }
    33% { transform: scale(1.1) }
    66% { transform: scale(.98) }
    100% { transform: scale(1) }
}

.popup {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    display: flex;
    z-index: 1000;
    will-change: opacity, visibility;
    transition: visibility .2s,
                opacity .2s,
                backdrop-filter .2s;

    &.visible {
        visibility: visible;
        opacity: 1;

        .popup-content {animation: pulse .4s}
     }

    .popup-title {
        @include label-m;
        text-align: center;
        display: block;
        padding: 1.5rem;
        border-bottom: 1px solid var(--grey-dark-40);
    }

    .popup-text {
        margin: 1.5rem;
        text-align: left;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        background: var(--grey-dark-40);
        backdrop-filter: blur(2px);
    }

    .popup-content {
        position: relative;
        max-width: 100%;
        border-radius: var(--border-radius-m);
        box-shadow: var(--box-shadow-m);
        background: var(--white);
        color: var(--dark-text);
        margin: auto;
    }

    .button--primary {margin-bottom: .5rem}

    .button--secondary {
        width: auto;
        padding: 1rem;
        margin: .5rem auto;
        color: var(--dark-text);
    }
}