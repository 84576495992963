@import '~styles/_mixins.scss';

.list-view {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    #title-container {flex: 0 0 auto}

    .header-wrap {
        position: sticky;
        top: 0;
        z-index: 1;
        flex: 0 0 auto;
        text-align: center;
        background: var(--main-background);
    }

    .button {
        @include big-button();

        svg {
            flex: 0 0 auto;
            margin-right: 1em;
        }
    }

    .title {
        @include label-m();
        position: relative;
        display: block;
        width: 35rem;
        max-width: 100%;
        padding-bottom: .5em;
        margin: 0 auto;
        text-align: center;
    }

    section {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;
        max-width: 100%;
        padding: 0 1em;
        margin: 0 auto;
    }

    ol {
        flex: 1 1 auto;
        width: 35rem;
        max-width: 100%;
        padding: 0;
        margin: 0 0 1rem;
        list-style: none;
    }

    li:not(:last-child) {margin-bottom: 1em}

    .date-divider {
        @include body-xs();
        display: flex;
        align-items: center;
        gap: 1rem;
        text-align: right;

        &::before {
            content: '';
            flex: 1 1 auto;
            height: 1px;
            background: currentColor;
            opacity: .1;
        }
    }
}
