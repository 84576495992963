@import '~styles/_mixins.scss';

@keyframes slideUp {
    0% {transform: translateY(100%)}
    100% {transform: translateY(0)}

}

.camera-view {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    background: var(--main-background);

    > :first-child {flex: 1 1 auto}

    video {
        object-fit: cover;
        min-width: 100%;
        min-height: 0;
        height: 100%;
        background: var(--black);
    }

    p {
        @include body-xs();
        margin-bottom: 2rem;
    }

    .button {
        margin-top: 1em;

        &--secondary {
            background: var(--main-text);
            color: var(--main-background);
        }

        input {@include visually-hidden()}
    }

    &-actions {
        flex: 0 0 auto;
        padding: 1em 1.25em 2em;
        background: inherit;
        transform: translateY(100%);
        animation: slideUp .3s forwards;
    }
}
