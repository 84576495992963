@import '~styles/_mixins.scss';

// Needs higher specificity (with :root, in this case) to override base.scss [type='button'] styles
:root .card {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding: 1em;
    border-radius: var(--border-radius-s);
    background: var(--card-overlay);
    box-shadow: var(--card-shadow);
    overflow: hidden;
    margin-bottom: 1rem;

    @at-root :root.light #{&} {background: var(--grey-dark-3)}

    .card {
        &-content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding-right: 4rem;
            margin-right: 1em;
            text-align: left;
        }

        &-label {
            @include label-m();
            word-break: break-word;
        }

        &-secondary {
            @include body-xs();
            display: flex;
            flex-direction: column;
            margin-top: .5em;
        }
    }
}
