@import '~styles/_mixins.scss';


.mileage-card {
    
    &.unopenable {
        opacity: .5;

        .unopenable-card-info {
            @include label-m();
            font-style: italic;
            display: block;
            margin: 1rem auto;
            text-align: center;
        }
    }

    .mileage-card-driver{
        background: var(--Branding-Middle-Grey);
        color: black;
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
        padding: .7rem .5rem;

        &.is-user {background: var(--Branding-Light-Green)}
    }

    .mileage-card-content {
        padding: 1em;
        display: flex;
        flex-flow: row wrap;
        border-radius: var(--border-radius-s);
        text-align: center;
        overflow: hidden;
        flex: 0 0 100%;


        .mileage-card-trip {
            flex: 0 0 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .mileage-card-time {text-align: center}
        .mileage-card-date {@include body-xs()}

        .mileage-card-clock {
            @include h5();
            display: block;
        }

        .mileage-card-period {
            @include label-m();
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            gap: 1rem;
            margin: .5rem 1.75rem;
            color: var(--secondary-background);
        }

        .mileage-card-indicator {
            display: flex;
            flex: 1 1 auto;
            margin-left: auto;
            background: radial-gradient(var(--main-text) .1rem, transparent 0) 0 0 / .75rem .75rem;

            &::before {
                content: '';
                width: .75rem;
                height: .75rem;
                border-radius: 50%;
                background: var(--main-text);
            }

            &.until::before {margin-left: auto}
        }

        .mileage-card-address {
            @include label-s();
            flex: 0 0 40%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;

            &.since {text-align: left}
            &.until {text-align: right}
        }

        .mileage-card-details {
            flex: 0 0 100%;
            display: flex;
            overflow: hidden;

            &:not(:empty) {
                border-top: 1px solid var(--grey-dark-25);
                margin-top: 1rem;
            }
        }

        .mileage-card-detail {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column nowrap;
            padding: 1rem;

            &:last-child {padding-bottom: 0}

            &.current {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding-bottom: 0;
            }

            &.remarks {
                .mileage-card-value {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }

            &.reason {
                .mileage-card-value {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }

            & + .mileage-card-col {
                border-left: 1px solid var(--grey-dark-25);

                .mileage-card-detail {padding: 1rem}
            }
        }

        .mileage-card-col {
            flex: 1 1 100%;
            display: flex;
            flex-flow: column nowrap;

            .mileage-card-detail {
                flex: 1 1 auto;
                padding: 1rem 0;
                text-align: left;

                &:not(:first-child) {border-top: 1px solid var(--grey-dark-25)}
                &:last-child {padding-bottom: 0}
                &:first-child:last-child .mileage-card-value {-webkit-line-clamp: 3}
            }
        }

        .mileage-card-value {@include label-m()}

        .mileage-card-label {
            @include body-xxs();
            display: block;
            padding-top: .5rem;
            text-align: center;
        }
    }
}
