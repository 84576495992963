@charset "utf-8";

@import '_variables.scss';
@import '_mixins.scss';

::selection {background: var(--highlight)}
img::selection {background: var(--fleet-blue-translucent)}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:root {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1.25;
    background: var(--main-background) !important;
    scroll-behavior: smooth;
    color: var(--main-text);
    font-family: var(--font-stack-primary);
    transition: all .25s;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    overflow-y: auto;
}

h1 {
    @include h1();
    margin: 0;
}

h2 {
    @include h2();
    margin: 0;
}

h3 {
    @include h3();
    margin: 0;
}

h4 {
    @include h4();
    margin: 0;
}

h5 {
    @include h5();
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color .2s;

    &:hover {
        text-decoration: none;
        color: var(--light-text-color);
    }
}

label {
    display: inline;
    margin: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

address {margin: 0}
table {border-spacing: 0}
dl {margin: 0}
dd {margin: 0}

fieldset {
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
}

button,
[type='button'],
[type='submit'],
[type='reset'] {
    @include button-text();
    appearance: none;
    align-items: center;
    font-family: inherit;
    padding: 0;
    border: 0;
    margin: 0 auto;
    border-radius: 1.5em;
    background: none;
    line-height: inherit;
    color: inherit;
    user-select: none;
    transition: color .2s,
                filter .2s,
                opacity .2s;

    &:not(:disabled) {cursor: pointer}

    &:not(:disabled):hover {
        text-decoration: none;
        filter: brightness(1.1);
    }

    &:not(:disabled):focus {outline: none;}
    &:not(:disabled):active {filter: brightness(.9)}

    &[disabled],
    &.disabled,
    &:disabled {
        filter: saturate(.2)
                contrast(.5)
                brightness(1.5);

        * {pointer-events: none !important} /* stylelint-disable-line */
    }
}

body .button { // Higher specificity to override input[*] styles
    @include button-text();
    align-items: center;
    display: inline-block;
    background: none;
    border: 0;
    padding: 0;
    margin: 0 auto;
    border-radius: 1.5em;
    text-align: center;
    cursor: pointer;

    &--primary {@include button-primary()}
    &--secondary {@include button-secondary()}

    &--link {
        @include button-text();
        background: none;
        border: 0;
        text-decoration: underline;
        transition: color .2s;
    }
}

[type='text'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'],
[type='date'],
[type='datetime-local'],
[type='time'] {@include text-input-field()}

textarea {
    @include text-input-field();
    display: block;
    min-height: 4em;
}

select {
    @include text-input-field();
    font-size: 1em;
    cursor: pointer;
}

#root {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.tag {@include tag()}

[data-tooltip] {
    position: relative;

    // Fix tooltip z-index. Maybe rethink button filters?
    &:not([disabled]):hover {filter: none !important;} /* stylelint-disable-line */

    &:disabled::before {display: none;}

    &::before {
        @include body-xxs();
        content: attr(data-tooltip);
        opacity: 0;
        position: absolute;
        top: 95%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        padding: .5rem;
        box-shadow: var(--box-shadow-s);
        border-radius: var(--border-radius-xs);
        background: var(--white);
        color: transparent;
        pointer-events: none;
        text-align: left;
        white-space: pre-wrap;
        transition: all .2s,
                    color .2s;
    }

    &:hover::before {
        top: 105%;
        opacity: 1;
        color: var(--dark-text);
    }

    &.tooltip-right {
        &::before {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 95%;
            transform: translateY(-50%);
        }

        &:hover::before {
            left: 105%;
            bottom: auto;
        }
    }

    &.tooltip-left {
        &::before {
            top: 50%;
            right: 95%;
            bottom: auto;
            left: auto;
            transform: translateY(-50%);
        }

        &:hover::before {
            right: 105%;
            bottom: auto;
        }
    }
}

svg {
    opacity: 1;
    path {transition: color .25s}

    .invert-dark {fill: var(--white)}
    .invert-light {fill: var(--theme-dark-accent)}
}
