@import '~styles/_mixins.scss';

#add-mileage {
    .editability-note {
        @include label-m();
        display: block;
        font-style: italic;
        margin-bottom: 1rem;
        text-align: center;
    }

    .add-mileage-form {
        width: 49em;
        max-width: 100%;
        padding: 0 1.5em 2em;
        margin: 0 auto;

        .button--primary {margin: 3rem auto 1.5rem}

        .form-label {margin-bottom: 1em}

        .button-container {
            background: var(--main-background);
            z-index: 2;
        }
    }
}
