@import '~styles/_mixins.scss';

#journey {
    padding: 1.5em;

    ol {
        list-style: none;
        padding: 0;
        margin: 1rem 0 0 0;
    }

    .approximates-disclaimer {@include body-xs()}

    .content {
        display: flex;
        flex-flow: column nowrap;
        width: 35em;
        max-width: 100%;
        margin: 0 auto;
    }

    .title {
        @include label-m();
        display: block;
        margin: .5rem auto 1.5rem;
        text-align: center;
    }

    .dropdown {
        align-self: flex-end;

        &-label {@include body-xs()}

        &-toggle {
            background: none;
            border: none;

            svg {color: var(--main-text)}
        }

        .chosen {
            @include label-s();
            color: var(--main-text);
        }

        .list button {
            @include label-s();
            color: var(--grey-dark-40);

            &.selected {
                background: none;
                color: var(--dark-text);
            }
        }
    }
    .disabled {
        opacity: 1;
        background: var(--grey-dark-40);
        color: var(--grey-dark-40);
    }
}
