#confirm-booking {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        margin: 1.5rem;
        border-radius: 3.5px;
    }

    .card-content {
        display: flex;
        flex-flow: column nowrap;
        background: var(--card-overlay);
        margin: 1.5rem;
        padding: 1.5rem;
        border-radius: 3.5px;
    }
}
