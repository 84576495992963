@import '~styles/_mixins.scss';

.journey-list-item {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;

    &:first-child {
        padding-bottom: 1em;
        border-bottom: 1px dashed;
    }

    &.in-progress {
        .journey-action-button {background: var(--file-red)}
    }

    div {
        flex: 1 1 auto;
        padding: .75em 1em;
        border-radius: var(--border-radius-m);
        background: var(--card-overlay);
        color: var(--main-text);
        text-align: left;
        overflow: hidden;

        @at-root :root.light #{&} {box-shadow: var(--box-shadow-s)}
    }

    .customer-name {
        @include label-s();
        display: block;
        margin-bottom: .25rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .journey-duration {@include body-xs()}

    .journey-action-button {
        @include label-s();
        flex: 0 0 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 5rem;
        padding: .75rem;
        border-radius: var(--border-radius-m);
        background: var(--secondary-background);
        color: var(--dark-text);
    }
}
