@import '~styles/_mixins.scss';

#booking {

    .deleted {display: none !important}

    .card-with-image {
        flex: 0 0 calc(20% - 2em);
        display: block;
        margin: 0 1em 2em;

        .card {
            &-image-wrap {
                position: relative;
                order: -1;
                width: 100%;
                height: 12vw;
            }

            &-content {
                width: 100%;
                padding-right: 0;
            }

            &-secondary {
                @include body-s();
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                span:first-child {margin-right: 2em}
            }
        }
        @media only screen and (max-width: 62em) {flex-basis: calc(25% - 2em)}
        @media only screen and (max-width: 50em) {flex-basis: calc(33.33333% - 2em)}

        @media only screen and (max-width: 45em) {
            flex-basis: calc(100% - 2em);
            display: flex;
            flex-wrap: nowrap;
            padding: 0;
            margin-bottom: 1em;

            .card {
                &-content {
                    align-self: stretch;
                    padding: 1em;
                }

                &-image-wrap {
                    flex: 0 0 33%;
                    width: 33%;
                    height: auto;
                    align-self: stretch;
                }

                &-secondary {@include body-xs()}
            }
        }
    }

}
