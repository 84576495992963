@import 'styles/_mixins.scss';

#title-container {
    display: flex;
    position: relative;
    text-align: center;
    height: 4.5rem;
    overflow: hidden;

    a {
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.5em;
        height: 4.5em;
    }

    svg {
        box-sizing: content-box;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        color: var(--main-background);
        background-color: var(--main-text);
        transition: background-color .25s;
    }

    h1 {
        all: unset;

        @include label-m();
        padding: 0 5rem;
        margin: auto;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            width: 3rem;
            pointer-events: none;
            box-shadow: inset -4em 0 1em -2em var(--main-background);
            transition: box-shadow .25s;
        }
    }
}
