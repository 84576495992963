@import '~styles/_mixins.scss';

.field-error {
    @include body-s();
    visibility: hidden;
    opacity: 0;
    position: relative;
    top: -1rem;
    white-space: pre-wrap;
    color: var(--error);
    transition: visibility .2s,
                opacity .2s,
                top .2s;

    &.has-error {
        visibility: visible;
        opacity: 1;
        top: 0;
    }
}
