:root {
    --main-background: #262626;
    --main-text: var(--white);
    --secondary-background: #12cc94;
    --dark-text: #102542;
    --highlight: #f7b8c7;
    --fade-background: rgba(0, 0, 0, .5);
    --card-overlay: rgba(255, 255, 255, .1);
    --file-red: #ffe1d7;

    // State indicator colors
    --error: #fa645f;
    --success: #5ae1cd;

    // Shades of grey
    --grey-dark-40: rgba(0, 0, 0, .4);
    --grey-dark-25: rgba(0, 0, 0, .25);
    --grey-dark-10: rgba(0, 0, 0, .1);
    --grey-dark-7: rgba(0, 0, 0, .07);
    --grey-dark-3: rgba(0, 0, 0, .03);
    --transparent: transparent;
    --white: #fff;
    --black: #000;

    // Brand colors
    --Branding-Middle-Grey: rgba(229,229,229);
    --Branding-Light-Green: rgba(223, 243, 245);

    // Box shadows
    --box-shadow-s: 0 2px 4px -1px rgba(0, 0, 0, .4);
    --box-shadow-m: 0 4px 8px -2px rgba(0, 0, 0, .4);
    --box-shadow-l: 0 8px 15px -4px rgba(0, 0, 0, .4);

    // Borders
    --border-gray: 1px solid rgba(0, 0, 0, .07);
    --border-radius-xxs: 2px;
    --border-radius-xs: 3px;
    --border-radius-s: 4px;
    --border-radius-m: 8px;

    // Other
    --font-stack-primary: 'Open Sans Regular',
                          'OpenSans-Regular',
                          'Open Sans',
                          'OpenSans',
                           sans-serif;
}

:root.light {
    --main-background: var(--white);
    --main-text: var(--dark-text);
    --fade-background: rgba(255, 255, 255, .5);
    --card-overlay: var(--main-background);
    --card-shadow: var(--box-shadow-s);
}
