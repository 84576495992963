@import '~styles/_mixins.scss';

@keyframes pulse {
    0% { transform: scale(1) }
    33% { transform: scale(1.1) }
    66% { transform: scale(.98) }
    100% { transform: scale(1) }
}

.journey-modal-container {
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 1rem 0;
    will-change: opacity, visibility;
    transition: visibility .2s,
                opacity .2s,
                backdrop-filter .2s;

    &.open {
        visibility: visible;
        opacity: 1;

        .modal {animation: pulse .4s}
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background: var(--grey-dark-40);
        filter: none !important;
        backdrop-filter: blur(2px);
    }

    .modal {
        position: relative;
        max-width: 100%;
        border-radius: var(--border-radius-m);
        box-shadow: var(--box-shadow-m);
        background: var(--white);
        color: var(--dark-text);
        margin: auto;
    }

    .title {
        padding: 1rem;
        border-bottom: 1px solid var(--grey-dark-25);
    }

    .form-label {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .autocomplete-container {
        margin: 0 1rem 1rem;

        .form-label {margin: 0}
    }

    .button--secondary {
        width: auto;
        padding: 1rem;
        margin: .5rem auto;
        color: var(--dark-text);
    }
}
