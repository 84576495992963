@import '~styles/_mixins.scss';

#home {
    min-height: 100%;
    padding: 1.5em;

    .car-image-btn {
        position: relative;
        display: block;

        svg {
            position: absolute;
            right: .5em;
            bottom: .5em;
            width: auto;
            height: auto;
            padding: .5em;
            background: var(--main-background);
            border-radius: 50%;
        }
    }

    .selected-vehicle-image {
        display: block;
        max-height: 35vh;
        margin: 0 auto;
    }

    .data-card {
        display: flex;
        justify-content: flex-end;
        background: var(--card-overlay);
        border-radius: var(--border-radius-s);
        box-shadow: var(--card-shadow);
        width: 70em;
        max-width: 100%;
        margin: 1em auto;
        overflow: hidden;

        &.vehicle-data {
            .info-content {
                flex: 1;
                text-align: left;

                span {
                    display: block;
                    margin: .5em 0;
                }
            }
        }

        &.driving-button {
            @include label-m();
            justify-content: center;
            padding: 1.5rem;
            border-radius: var(--border-radius-m);
            background: var(--secondary-background);
            color: var(--dark-text);
        }

        &-header.info-square {
            flex: 1;
            display: block;
            padding-left: 2em;
            padding-right: 2em;
            text-align: left;
            white-space: nowrap;

            &:first-child:last-child {padding: 2.536em 2em}

            &::before {
                content: '';
                width: 0;
                height: 100%;
                display: inline-block;
                vertical-align: middle;
            }
        }

        &-header-inner {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            white-space: normal;
        }

        &-title {
            display: inline-block;
            vertical-align: middle;
            margin: .5em 1em;
            text-align: center;
        }

        svg {
            margin: 0 auto;
            vertical-align: middle;

            path.secondary {fill: var(--main-background)}
        }
    }

    .info-square {
        @include body-s();
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        width: 36%;
        padding: 1em .5em;
        border-right: 1px solid var(--main-background);
        text-align: center;


        &:last-child {border-right: 0}

        &:empty {
            visibility: hidden;
            flex: 0 0 0;
            padding: 0;
            border: none;
        }

        &-label {display: block}
        &-date {opacity: .5}

        &-value {
            @include h4();
            margin-top: 1rem;
        }

        &-value-alternate {@include h4()}

        &-unit {
            @include body-xs
        }
    }

    @media screen and (max-width: 42.3125rem) {
        .info-square.data-card-header {
            text-align: center;
            padding-left: .5em;
            padding-right: .5em;

            &:first-child:last-child {padding: 1.679em .5em}
        }

        .data-card-title {
            display: block;
            margin-left: .5em;
            margin-right: .5em;
        }

    }
}
