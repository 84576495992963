@import 'styles/_mixins.scss';

#global-header {
    display: flex;
    background: var(--main-background);
    justify-content: space-between;
    text-align: center;
    margin: -1.5rem -1.5rem 0;

    > a {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        word-break: break-word;
    }

    .change-vehicle {
        @include body-m();
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: auto;

        .plate {
            @include label-l();
            color: inherit;
        }
    }
}
